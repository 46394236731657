.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  max-width: 530px;
  max-height: 530px;
  object-fit: contain;
  border-radius: 8px;
}

.swiper {
  width: 100%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
}

.swiper-slide {
  background-size: cover;
  background-position: center;
}

.mySwiper2 {
  height: 80%;
  width: 100%;
}

.mySwiper {
  height: auto;
  box-sizing: border-box;
  padding: 10px 0;
  padding-left: 2px;
  padding-right: 2px;
}

.mySwiper .swiper-slide {
  width: 25%;
  height: 100%;
}

.swiper-slide-visible div {
  border: 1px solid #d5d9d9;
}
.swiper-slide-thumb-active div {
  border: 1px solid #47a06d;
  background-color: #409a6510;
}

.swiper-slide-thumb-active div p {
  font-weight: 700;
}
