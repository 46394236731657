::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background: #e6e6e6;
  border-radius: 20px;
}

.pac-container {
  z-index: 100000;
}

body {
  /* overflow: overlay !important; */
  /* padding-right: 0px !important; */
  -webkit-tap-highlight-color: transparent;
}

@media (max-width: 600px) {
  body {
    overflow: hidden !important;
  }

  .Toastify__toast-theme--colored.Toastify__toast--error {
    color: #302c2c;
    background-color: #efd8db;
    box-shadow: none;
    margin: 16px;
    font-size: 12px;
    border-radius: 8px;
    border: 1px solid #edb3bb;
    height: fit-content;
    gap: 4px;
  }

  .Toastify__toast-theme--colored.Toastify__toast--error svg {
    color: #f95758;
  }

  .Toastify__toast-theme--dark {
    color: #302c2c;
    background-color: #dce6e0;
    box-shadow: none;
    margin: 16px;
    font-size: 12px;
    border-radius: 8px;
    gap: 4px;
    border: 1px solid #bdd7c7;
  }

  .Toastify__toast-icon {
    width: 16px;
    height: 16px;
    margin-inline-end: 8px;
  }

  .Toastify__close-button {
    display: none;
  }

  .Toastify__toast {
    min-height: 40px;
  }

  .Toastify__toast-body {
    margin: 0;
    padding: 0;
  }
}

input:-internal-autofill-selected {
  background-color: transparent !important;
}
